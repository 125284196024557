<template>
  <subspecialties-component />
</template>

<script>
import SubspecialtiesComponent from "@/components/admin/catalogs/SubspecialtiesComponent";

export default {
  name: "Subspecialties",
  title: "Subespecialidades | Baja California Health Tourism",
  components: { SubspecialtiesComponent },
};
</script>

<style scoped></style>

/********************************************************/
// Capa de Servicios para el modulo de Sub-Especialidades.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/subspecialties");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fatal:', error);
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(
        this.#baseUrl + "/view-list/subspecialties"
      );
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/subspecialties/${id}`);
      if (response.data.success) {
        //console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  addRecord(formData) {
    // .log('Adding Record.', formData);
    let recordData = {
      id: formData.id,
      name: formData.name,
      name_english: formData.name_english,
      translation_id: formData.translation_id,
      specialty_id: formData.specialty.id,
      enabled: formData.enabled,
    };
    // console.log('Real Adding Record.', recordData);
    let result = null;
    axios
      .post(this.#baseUrl + "/subspecialties", recordData, {
          headers: this.header
        })
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  // Pushes posts to the server when called.
  updateRecord(id, formData) {
    // console.log('Updating Record: %s %s ', id, formData);
    let recordData = {
      id: formData.id,
      name: formData.name,
      name_english: formData.name_english,
      translation_id: formData.translation_id,
      specialty_id: formData.specialty.id,
      enabled: formData.enabled,
    };
    // console.log('Real Adding Record.', recordData);
    let result = null;
    axios
      .put(`${this.#baseUrl}/subspecialties/${id}`, recordData, {
          headers: this.header
        })
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  deleteRecord(id) {
    let result = null;
    // console.log('Deleting Record: %s ', id);
    axios
      .delete(`${this.#baseUrl}/subspecialties/${id}`, {
          headers: this.header
        })
      .then((response) => {
        // console.log('Respuesta: ', response.data);
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error; // error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
